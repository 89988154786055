import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/O-Firmie/About'
import Tiles from 'components/layout/Sections/Pages/O-Firmie/Tiles'
import InfoWithButton from 'components/layout/InfoWithButton'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'O firmie',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'O firmie',
    },
  ],
}

const infoText =
  'Jako producent ogrodzeń metalowych podchodzimy do pracy z ogromną pasją i zaangażowaniem , czego efektem są doskonałej jakości nowoczesne systemy ogrodzeniowe. Wspierani najnowocześniejszą technologią, tworzymy bezpieczne i trwałe ogrodzenia.'

const AboutUsPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.oFirmie
  const PAGE_SEO = data?.wpPage?.seo

  const GALLERY = React.useMemo(
    () =>
      PAGE?.tilesSingleTile?.map((item) => ({
        img: item.tilesSingleTileImg.localFile.childImageSharp.gatsbyImageData,
        alt: item.tilesSingleTileImg.altText,
        label: item?.tilesSingleTileTileName,
        link: `${item?.tilesSingleTileUrl}/`,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.aboutUsImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.aboutUsImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.aboutUsTitle}
        headingDesktop={PAGE?.aboutUsTitle}
        breadcrumbs={breadcrumbs}
      />
      <About
        description1={PAGE?.aboutUsDescriptionLeft}
        description2={PAGE?.aboutUsDescriptionRight}
      />
      <Tiles gallery={GALLERY} />
      <InfoWithButton text={infoText} />
    </Layout>
  )
}

export default AboutUsPage

export const query = graphql`
  query AboutUsPage {
    wpPage(slug: { regex: "/o-firmie/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      oFirmie {
        aboutUsTitle
        aboutUsDescriptionLeft
        aboutUsDescriptionRight
        aboutUsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        tilesSingleTile {
          tilesSingleTileTileName
          tilesSingleTileImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
          tilesSingleTileUrl
        }
      }
    }
  }
`
