import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import { Heading } from 'components/shared/typography'
import { ImageDataLike } from 'gatsby-plugin-image'

type GalleryItem = {
  img: ImageDataLike
  alt: string
  label: string
  link: string
}

type Props = {
  gallery: GalleryItem[]
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)`
  padding: 0;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: ${({ theme }) => theme.container.paddingMD};
    padding-right: ${({ theme }) => theme.container.paddingMD};
  }
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 768px;
  margin: 0 auto;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    max-width: unset;
    overflow: hidden;
    border-radius: 30px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    gap: 15px;
    border-radius: 50px;
  }
`

const TileSingleWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  transition: 0.3s ease-in;

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 180px;
  }

  ${({ theme }) => theme.media.xl.min} {
    height: 220px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 270px;
  }
`

const TileHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.gray300};

  ${Heading} {
    br {
      display: none;
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 320px;

    ${Heading} {
      br {
        display: inline;
      }
    }
  }
`

const ImgWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    height: 100%;

    //Gatsby image wrapper
    & > div {
      height: 100%;
    }
  }
`

const Tiles: React.FC<Props> = ({ gallery }) => {
  return (
    <Section>
      <StyledContainer>
        <TilesWrapper>
          {gallery.map(({ img, alt, label, link }) => (
            <TileSingleWrapper key={label} as={Link} to={link}>
              <TileHeader>
                <Heading
                  as="h4"
                  size={30}
                  weight={600}
                  align="center"
                  themecolor="primary"
                  margin="0"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              </TileHeader>
              <ImgWrapper>
                <LazyImage src={img} alt={alt} />
              </ImgWrapper>
            </TileSingleWrapper>
          ))}
        </TilesWrapper>
      </StyledContainer>
    </Section>
  )
}

export default Tiles
