import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  description1: string
  description2: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0 0 20px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)``

const InnerWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
`

const Hr = styled.div`
  width: 100px;
  height: 5px;
  margin: 15px 0;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    margin: 0;
  }
`

const DescriptionWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: 30px;
    width: 45%;
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 48%;
  }
`

const About: React.FC<Props> = ({ description1, description2 }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <InnerWrapper>
          <DescriptionWrapper>
            <Hr />
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              dangerouslySetInnerHTML={{ __html: description1 }}
            />
          </DescriptionWrapper>
          <DescriptionWrapper>
            <Hr />
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
          </DescriptionWrapper>
        </InnerWrapper>
      </StyledContainer>
    </Section>
  )
}

export default About
